import React from 'react'
import {
  LandingWrapper,
  LandingTitle,
  LandingDescription,
  EmailWrapper,
  SocialMediaWrapper,
  SocialMediaLink,
  SocialMediaLogo,
} from './styles'

import { TelegramIcon } from 'icons/TelegramIcon'
import { TwitterIcon } from 'icons/TwitterIcon'
import { InstagramIcon } from 'icons/InstagramIcon'

export const Landing = () => {
  return (
    <LandingWrapper>
      <LandingTitle>For Your Eyes ONLY.</LandingTitle>
      <LandingDescription>Elevate your community’s experience and fulfill their passions & desires.</LandingDescription>
      <EmailWrapper>
        <script type="text/javascript" src="https://ma.zoho.com/js/zc.iframe.js"></script>
        <iframe
          id="iframewin"
          width="100%"
          height="120%"
          frameBorder="0"
          src="https://xegac-zgfl.maillist-manage.com/ua/Optin?od=11287ecbd3f039&zx=12ddc2807&lD=1c91cca2e06b21f6&n=11699f7518d1cd2&sD=1c91cca2e0d1abd7"
        ></iframe>
      </EmailWrapper>
      <SocialMediaWrapper>
        <SocialMediaLink href="https://t.me/ONLYonFTM" target="_blank" rel="noreferrer noopener">
          <SocialMediaLogo>
            <TelegramIcon />
          </SocialMediaLogo>
        </SocialMediaLink>
        <SocialMediaLink href="https://twitter.com/onlyonftm" target="_blank" rel="noreferrer noopener">
          <SocialMediaLogo>
            <TwitterIcon />
          </SocialMediaLogo>
        </SocialMediaLink>
        <SocialMediaLink href="https://www.instagram.com/onlyonftm/" target="_blank" rel="noreferrer noopener">
          <SocialMediaLogo>
            <InstagramIcon />
          </SocialMediaLogo>
        </SocialMediaLink>
      </SocialMediaWrapper>
    </LandingWrapper>
  )
}
