import React from 'react'

export const TwitterIcon = () => {
  return (
    <svg width="currentSize" height="currentSize" viewBox="0 0 181 146" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.083 17.8162C173.493 20.7247 166.404 22.6915 158.969 23.5774C166.561 19.0475 172.39 11.8821 175.133 3.3405C168.034 7.53053 160.162 10.5728 151.791 12.2164C145.084 5.10678 135.532 0.666016 124.961 0.666016C104.659 0.666016 88.2038 17.0417 88.2038 37.2452C88.2038 40.1091 88.5285 42.9006 89.1556 45.5807C58.6021 44.054 31.5199 29.4892 13.3905 7.3578C10.2271 12.7625 8.41302 19.0475 8.41302 25.7505C8.41302 38.4375 14.9022 49.637 24.762 56.195C18.7375 56.0056 13.0714 54.3619 8.11628 51.6205C8.11068 51.7766 8.11068 51.927 8.11068 52.083C8.11068 69.807 20.7811 84.5891 37.6004 87.949C34.5154 88.7847 31.268 89.2305 27.9142 89.2305C25.5458 89.2305 23.2447 89.002 20.9995 88.5786C25.6746 103.11 39.2521 113.685 55.3379 113.981C42.757 123.793 26.9064 129.638 9.68398 129.638C6.71654 129.638 3.79388 129.465 0.916016 129.13C17.181 139.505 36.503 145.562 57.2639 145.562C124.877 145.562 161.847 89.8211 161.847 41.4853C161.847 39.8974 161.813 38.3205 161.74 36.7493C168.924 31.5953 175.156 25.1543 180.083 17.8162Z"
        fill="#f9c7c4"
      ></path>
    </svg>
  )
}
