import { Header } from 'components/Header'
import React from 'react'
import styled from 'styled-components'
import { Landing } from './Landing'
import { Route, Routes } from 'react-router-dom'
import { NotFound } from './NotFound'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fde6e6;
  background-image: linear-gradient(191deg, rgba(18, 24, 38, 0.2), rgba(18, 24, 38, 0.3)),
    url(https://assets-global.website-files.com/61e59bfb4d2924127c32b427/61e5aa7f1a952725aa4a3348_Image-1.png);
  background-position:
    0 0,
    50%;
  background-size: auto, cover;
`

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppContainer>
            <Header />
            <Landing />
          </AppContainer>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
