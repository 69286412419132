import React from 'react'
import { Link } from 'react-router-dom'
import { NavBar, LogoImg, CreatorButton } from './styles'
import logo from 'assets/images/Only_Logo_Pink.png'

export const Header = () => {
  return (
    <NavBar>
      <Link to={'/'}>
        <LogoImg src={logo} />
      </Link>
      <CreatorButton
        target="_blank"
        rel="noreferrer noopener"
        to={
          'https://forms.zohopublic.com/quantumftg/form/OnlyNewCreatorsForm/formperma/TjqLHsJg96TgYcCjyCYcXhFgogaj_aKjhFxQrpfajMY'
        }
      >
        Become A Creator
      </CreatorButton>
    </NavBar>
  )
}
