import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NavBar = styled.nav`
  width: 90vw;
  height: 4vh;
  background-color: rgba(221, 221, 221, 0);
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LogoImg = styled.img`
  width: 150px;
  height: 80px;
`

export const CreatorButton = styled(Link)`
  height: 50px;
  color: #121826;
  background-color: #f9c7c4;
  background-image: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-weight: 700;
  cursor: pointer;
  border: 0;
  text-decoration: none;
`
