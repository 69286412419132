import styled from 'styled-components'

export const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: start;
  padding: 0 5vw;
`

export const LandingTitle = styled.h1`
  font-family: 'ITC Avant Garde Gothic Pro', sans-serif;
  color: #fde6e6;
  font-size: 55px;
  line-height: 1.3;
  margin: 20px 0 3vh;
`

export const LandingDescription = styled.p`
  font-family: 'ITC Avant Garde Gothic Pro', sans-serif;
  color: #fde6e6;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0 0 3vh;
`

export const EmailWrapper = styled.div``

export const SocialMediaWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1vw;
  margin-top: 2vh;
`

export const SocialMediaLink = styled.a`
  text-decoration: none;
  color: #fde6e6;
`

export const SocialMediaLogo = styled.div`
  width: 50px;
  height: 40px;
`
