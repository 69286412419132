import React from 'react'

export const TelegramIcon = () => {
  return (
    <svg width="currentSize" height="currentSize" viewBox="0 0 173 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4991 171.452C39.0851 171.452 0.648438 133.194 0.648438 86.0002C0.648438 38.8067 39.0851 0.548828 86.4991 0.548828C133.913 0.548828 172.35 38.8067 172.35 86.0002C172.35 133.194 133.913 171.452 86.4991 171.452ZM34.0987 82.6274C33.1858 82.9883 32.4526 83.6928 32.0581 84.5882C31.2302 86.4674 32.0895 88.6588 33.9774 89.4828L54.7133 98.5338C55.7695 98.9948 56.5522 99.9159 56.8339 101.029L62.8118 124.655C62.9718 125.287 63.2961 125.867 63.7523 126.335C65.1877 127.807 67.5509 127.843 69.0305 126.414L78.3466 117.418C79.6359 116.173 81.6332 116.019 83.1001 117.051L102.08 130.403C102.498 130.697 102.972 130.902 103.474 131.006C105.492 131.424 107.468 130.135 107.889 128.126L123.502 53.4853C123.651 52.773 123.586 52.0329 123.317 51.3567C122.556 49.4497 120.386 48.5177 118.47 49.2751L34.0987 82.6274Z"
        fill="#f9c7c4"
      ></path>
    </svg>
  )
}
